<template>
  <div class="live-wall">
    <posts-list :loading="isLoading" />
    <add-post />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { GET_LIVEWALL_POSTS } from "@/store/modules/livewall/action-types";
import { livewall } from "@/store/modules/store.namespaces";
import { CLEAR_POSTS, SET_COMPLETE } from "@/store/modules/livewall/mutation-types";
import PostsList from "./components/PostsList";
import AddPost from "./components/AddPost";

export default {
  name: "LiveWall",
  components: {
    PostsList,
    AddPost,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(livewall, {
      getLiveWallPosts: GET_LIVEWALL_POSTS,
    }),
    ...mapMutations(livewall, {
      clearPosts: CLEAR_POSTS,
      setComplete: SET_COMPLETE,
    }),
    async init() {
      try {
        this.isLoading = true;
        this.resetLiveWall();
        await this.getLiveWallPosts();
        // TODO: Временное решение, потом необходимо перенести в action getLiveWallPosts.
        this.setComplete(true);
      } finally {
        this.isLoading = false;
      }
    },
    resetLiveWall() {
      this.clearPosts();
      this.setComplete(false);
    },
  },
};
</script>

<style scoped lang="less">
.live-wall {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
</style>
