<template>
  <loader v-if="loading" center />
  <transition-group
    v-else-if="posts && posts.length"
    class="live-wall-posts"
    name="flip-list"
    tag="div"
  >
    <div v-for="post in posts" :key="post.id" class="post">
      <div class="post__head">
        <div class="author-photo">
          <img v-if="post.user_photo" :src="post.user_photo" alt />
          <i v-else class="fas fa-user-circle"></i>
        </div>
        <div class="head-info">
          <div class="head-info__author">
            {{ post.is_guest ? $t("common.guest") : post.user_fio }}
          </div>
          <div class="head-info__date">
            {{ timestampToString(post.createdDate) }}
          </div>
        </div>
      </div>

      <div class="post__wrapper">
        <div
          v-for="(photo, index) in post.photos"
          :key="index"
          class="post__img"
        >
          <img :src="photo" alt />
        </div>
      </div>

      <p class="post__text">{{ post.text }}</p>
      <div
        class="post__likes"
        :class="{ 'post__likes--my-like': post.is_liked }"
        @click="switchLiveWallPostLike(post.id)"
      >
        <span class="likes-count">{{ post.likes }}</span>
        <ws-icon color="primary" :light="!post.is_liked">thumbs-up</ws-icon>
      </div>
    </div>
    <infinite-loading ref="infiniteLoader" key="infinite-loader" @infinite="loadMore">
      <template #spinner>
        <loader />
      </template>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </transition-group>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapActions, mapMutations, mapState } from "vuex";
import Loader from "@/components/common/elements/Loader";
import { livewall, room } from "@/store/modules/store.namespaces";
import {
  GET_LIVEWALL_POSTS,
  SWITCH_LIVEWALL_POST_LIKE,
} from "@/store/modules/livewall/action-types";
import WsIcon from "@/components/base/WsIcon";
import { SET_COMPLETE } from "@/store/modules/livewall/mutation-types";

export default {
  name: "PostsList",
  components: {
    WsIcon,
    Loader,
    InfiniteLoading,
  },
  props: {
    loading: Boolean,
  },
  computed: {
    ...mapState(livewall, ["posts", "isComplete"]),
    ...mapState(room, {
      locale: state => state.interfaceLanguage.slice(0, 2),
    }),
  },
  beforeMount() {
    this.$moment.locale(this.locale);
    this.$bus.$on("addLiveWallPost", () => {
      if (this.isComplete) {
        this.$el.scrollTop = this.$el.scrollHeight;
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("addLiveWallPost");
  },
  methods: {
    ...mapActions(livewall, {
      getLiveWallPosts: GET_LIVEWALL_POSTS,
      switchLiveWallPostLike: SWITCH_LIVEWALL_POST_LIKE,
    }),
    ...mapMutations(livewall, {
      setComplete: SET_COMPLETE,
    }),
    timestampToString(value) {
      return this.$moment
        .unix(value)
        .format(`DD MMMM ${this.$t("LiveWall.at")} HH:mm`);
    },
    async loadMore($state) {
      const res = await this.getLiveWallPosts(this.posts.length);
      if (res && res === "complete") {
        $state.complete();
        this.setComplete(true);
      } else {
        $state.loaded();
        this.setComplete(false);
      }
    },
  },
};
</script>

<style scoped lang="less">
.flip-list-move {
  transition: transform 1s !important;
  z-index: 111;
  position: relative;
}
.flip-list-move ~ .questions-list__item:not(.flip-list-move) {
  opacity: 0.5;
}

.loader {
  margin: 20px auto;
}

.live-wall-posts {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 10px;
  margin-top: 20px;

  .post {
    border: 1px solid #c1c6c8;
    border-radius: 4px;
    padding: 15px;
    margin: 0 auto 15px;
    max-width: 600px;
    width: 100%;
    word-wrap: break-word;
    background-color: #ffffff;

    &__head {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .author-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 10px;

        & > img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          overflow: hidden;
          border-radius: 50%;
        }

        i {
          font-size: 48px;
          color: #d8d8d8;
        }
      }

      .head-info {
        &__author {
          font-size: 15px;
          line-height: 1.3;
          color: var(--base-color);
        }

        &__date {
          font-size: 13px;
          line-height: 1.2;
          color: #90949a;
        }
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-gap: 10px;
      grid-auto-flow: dense;

      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(176px, 1fr));
      }
    }

    &__img {
      & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.post__img {
        overflow: hidden;

        @media (min-width: 768px) {
          &:nth-child(4) {
            grid-area: span 2 / span 2;
          }

          &:nth-child(10) {
            grid-area: span 1 / span 3;
          }
        }
      }
    }

    &__text {
      margin-bottom: 15px;
      font-size: 15px;
      line-height: 1.3;
      color: #303030;
      white-space: pre-wrap;
    }

    &__likes {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      color: var(--base-color);
      cursor: pointer;

      & > .likes-count {
        margin-right: 8px;
        font-size: 13px;
      }
    }
  }
}
</style>
