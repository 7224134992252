<template>
  <div
    class="add-post"
    :class="
      $mq
        | mq({
          ['mobile-s']: 'add-post--mobile',
          tablet: '',
        })
    "
  >
    <div ref="testWidth" class="test-message-width">{{ message }}</div>
    <div class="add-post__container" :class="{ 'add-post__container--expanded': isExpanded }">
      <form
        class="add-post__form"
        enctype="multipart/form-data"
        @keydown="addNewLine($event)"
        @keydown.prevent.enter.exact="!isSaving && addPost()"
      >
        <mq-layout :mq="$mqMobileKeys">
          <div v-show="files.length > 0" class="add-post__previews-wrapper">
            <div
              v-for="(filePreview, index) in filePreviews"
              :key="index"
              class="add-post__file-preview"
            >
              <img :src="filePreview" />
              <div class="add-post__remove-file" @click="removeFile(index)">
                &times;
              </div>
            </div>
          </div>
          <div class="add-post__message-wrapper">
            <div class="add-post__file">
              <input
                id="add-post-file"
                type="file"
                accept="image/*"
                multiple
                @change="processFile($event.target.files)"
              />
              <label for="add-post-file" class="file-icon icon-link" />
            </div>
            <textarea
              ref="textarea"
              v-model="message"
              rows="5"
              class="add-post__input"
              :class="{ 'add-post__input--expanded': removeTopPadding }"
              :placeholder="$t('LiveWall.placeholder') + '...'"
            />
            <ws-button
              icon
              color="default"
              :disabled="isSaving"
              class="add-post__button"
              @click="addPost"
            >
              <ws-icon light xxl color="primary">paper-plane</ws-icon>
            </ws-button>
          </div>
        </mq-layout>
        <mq-layout mq="tablet+">
          <div class="add-post__message-wrapper">
            <div class="add-post__avatar">
              <img v-if="avatar" :src="avatar" />
              <ws-icon v-else>user-circle</ws-icon>
            </div>
            <textarea
              ref="textarea"
              v-model="message"
              rows="5"
              class="add-post__input"
              :class="{ 'add-post__input--expanded': removeTopPadding }"
              :placeholder="$t('LiveWall.placeholder') + '...'"
            />
            <div
              v-show="!isTyping && files.length === 0"
              class="add-post__file add-post__file--right"
            >
              <input
                id="add-post-file-right"
                type="file"
                accept="image/*"
                multiple
                @change="processFile($event.target.files)"
              />
              <label for="add-post-file-right" class="file-icon icon-link" />
            </div>
          </div>
          <div v-show="isShowControlsWrapper" class="add-post__controls-wrapper">
            <div class="add-post__previews-wrapper">
              <div
                v-for="(filePreview, index) in filePreviews"
                :key="index"
                class="add-post__file-preview"
              >
                <img :src="filePreview" />
                <div class="add-post__remove-file" @click="removeFile(index)">
                  &times;
                </div>
              </div>
            </div>
            <div class="add-post__file">
              <input
                id="add-post-file-bottom"
                type="file"
                accept="image/*"
                multiple
                @change="processFile($event.target.files)"
              />
              <label for="add-post-file-bottom" class="file-icon icon-link" />
            </div>
            <ws-button
              color="primary"
              class="add-post__button"
              :disabled="isSaving"
              @click="addPost"
            >
              {{ $t("common.send") }}
            </ws-button>
          </div>
        </mq-layout>
      </form>
    </div>
    <simple-modal v-if="showModal" custom-width="315px" class="modal">
      <div slot="header" class="modal__header">
        {{ $t("common.filesLimit") }}
      </div>
      <div slot="footer" class="modal__footer">
        <ws-button color="primary" @click="closeModal">
          {{ $t("common.okText") }}
        </ws-button>
      </div>
    </simple-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { auth, livewall, room } from "@/store/modules/store.namespaces";
import { ADD_LIVEWALL_POST } from "@/store/modules/livewall/action-types";

import SimpleModal from "@/components/event/layouts/modals/SimpleModal";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

const MAX_FILES = 10;
export default {
  name: "AddPost",
  components: { SimpleModal, WsButton, WsIcon },
  data() {
    return {
      message: "",
      files: [],
      filePreviews: [],
      isSaving: false,
      removeTopPadding: false,
      showModal: false,
    };
  },
  watch: {
    message() {
      this.removeTopPadding = this.checkPadding();
    },
    files(fileList) {
      const fileUrls = [];

      Array.from(fileList).forEach(file => {
        fileUrls.push(URL.createObjectURL(file));
      });

      this.filePreviews = fileUrls;
    },
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(room, ["roomId"]),
    avatar() {
      return this.user.photoWithUrl;
    },
    isTyping() {
      return this.message.length > 0;
    },
    isExpanded() {
      return (this.isTyping && !this.$mqMobile) || this.files.length > 0;
    },
    isShowControlsWrapper() {
      return this.isTyping || this.files.length > 0;
    },
  },
  methods: {
    ...mapActions(livewall, {
      addLivewallPost: ADD_LIVEWALL_POST,
    }),
    processFile(files) {
      this.files.push(...files);
      if (this.files.length > MAX_FILES) {
        this.files.splice(MAX_FILES);
        this.showModal = true;
      }
    },
    removeFile(index) {
      const filesArray = Array.from(this.files);
      filesArray.splice(index, 1);
      this.files = filesArray;
    },
    addPost() {
      if (this.message) {
        this.isSaving = true;
        const formData = new FormData();
        formData.append("text", this.message);
        formData.append("room_id", this.roomId);
        Array.from(this.files).forEach(item => {
          formData.append("photos[]", item);
        });
        this.addLivewallPost(formData)
          .then(() => {
            this.message = "";
            this.files = [];
            this.filesCounter = null;
            this.isSaving = false;
            this.$bus.$emit("addLiveWallPost");
          })
          .catch(() => {
            this.isSaving = false;
          });
      }
    },
    addNewLine(event) {
      if (event.ctrlKey && event.keyCode === 13) {
        this.message += "\n";
      }
    },
    checkPadding() {
      const PADDING = this.$mqMobile ? 61 : 5;
      const ROWS_TO_REMOVE_PADDING = this.$mqMobile ? 1 : 2;
      const { textarea } = this.$refs;

      if (!textarea || !this.message) {
        return false;
      }

      const textareaWidth = (textarea.clientWidth - PADDING) * ROWS_TO_REMOVE_PADDING;
      const messageWidth = this.$refs.testWidth.clientWidth;

      return !!(messageWidth > textareaWidth || this.message.indexOf("\n") !== -1);
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped lang="less">
.modal {
  &__header {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  &__footer {
    text-align: center;
    padding-bottom: 10px;
  }
}
.add-post {
  flex-shrink: 0;
  margin-top: auto;
  padding: 10px 10px 15px;
  position: relative;

  &--mobile {
    border-top: 1px solid #c1c6c8;

    .add-post__container {
      border: none;
      height: max-content;
    }

    .add-post__form {
      padding: 0;

      & > div {
        height: 100%;
      }
    }

    .add-post__previews-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-gap: 10px;
      grid-auto-flow: dense;
      margin-right: 0;
      margin-bottom: 5px;
      width: 100%;
    }

    .add-post__message-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      margin-bottom: 0;
      position: relative;
    }

    .add-post__file {
      margin-right: 10px;
    }

    .add-post__input {
      padding: 14px 36px 0 25px;
      border: 1px solid #c1c6c8;
      border-radius: 8px;

      &--expanded {
        padding-top: 0;
        max-height: 65px;
      }
    }

    .add-post__button {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 100%;
      border-radius: 0;
    }
  }

  &__container {
    max-width: 600px;
    width: 100%;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #c1c6c8;
    border-radius: 8px;
    background-color: white;
  }

  &__form {
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__message-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 65px;
  }

  &__avatar {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      display: block;
      max-width: 100%;
    }

    i {
      font-size: 48px;
      color: #d8d8d8;
    }
  }

  &__input {
    display: block;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    max-height: 48px;
    outline: none;
    resize: none;
    padding: 15px 5px 0 0;
    font-size: 15px;
    line-height: 1.33;
    color: #000000;
    text-align: left;
    border: none;
    transition: all ease 0.3s;

    &--expanded {
      padding-top: 0;
      max-height: 65px;
    }
  }

  &__controls-wrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &__previews-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 10px;
    grid-auto-flow: dense;
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
  }

  &__file-preview {
    position: relative;
    height: 90px;

    & > img {
      display: inline-block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__remove-file {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 15px;
    height: 15px;
    padding: 2px;
    font-size: 24px;
    line-height: 11px;
    color: #ffffff;
    background-color: #313131;
    cursor: pointer;

    &:hover {
      background-color: red;
    }
  }

  &__file {
    width: 28px;
    height: 100%;
    position: relative;
    margin-right: 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &--right {
      margin-right: 0;
    }

    & > input {
      opacity: 0;
      z-index: -1;
      position: absolute;
      width: 28px;
      height: 100%;
      cursor: pointer;
    }

    & > .file-icon {
      display: inline-block;
      font-size: 28px;
      color: var(--base-color);
      cursor: pointer;
    }
  }

  &__button {
    min-width: auto;
    padding: 5px 25px;
  }
}

.test-message-width {
  display: block;
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
}
</style>
